<template>
  <div>
    <el-card class="box-card">
      <span class="header">角色管理</span>
      <el-form
        :inline="true"
        class="demo-form-inline"
        :model="authority.userInfor"
        :rules="addUserRules"
        ref="ruleForm"
        status-icon
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="员工等级">
              <el-select v-model="authority.userInfor.roleName" placeholder="请选择员工等级">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="员工姓名">
              <el-input v-model="authority.userInfor.userName" placeholder="请输入员工姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属部门">
              <el-select v-model="authority.userInfor.dept" placeholder="请选择员工部门">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属职位">
              <el-select v-model="authority.userInfor.position" placeholder="请选择员工职位">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="phone" label="手机号">
              <el-input
                @blur.capture="onChange"
                v-model="authority.userInfor.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="初始密码" prop="password">
              <el-input v-model="authority.userInfor.password" placeholder="请输入初始密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div class="quanxian">权限编辑</div>
    <el-card class="dengji">
      <div class="text">审核模块</div>
      <el-switch v-model="authority.approveModel.orgApprove" inactive-text="合作机构审核"></el-switch>
      <el-switch
        class="dengjiSecond"
        v-model="authority.approveModel.subjectApprove"
        inactive-text="主体信息审核"
      ></el-switch>
      <el-switch
        class="dengjiSecond"
        v-model="authority.approveModel.registerApprove"
        inactive-text="登记信息审核"
      ></el-switch>
      <el-switch
        class="dengjiSecond"
        v-model="authority.approveModel.industryApprove"
        inactive-text="舆情审核"
      ></el-switch>
      <el-switch
        class="dengjiSecond"
        v-model="authority.approveModel.offerApprove"
        inactive-text="车型报价审核"
      ></el-switch>
    </el-card>

    <div class="zhuti">
      <el-card class="dengji">
        <div class="text">账号管理模块</div>
        <el-switch v-model="authority.accountModel.staffAuthority" inactive-text="合作机构账号添加"></el-switch>
        <el-switch
          class="dengjiSecond"
          v-model="authority.accountModel.orgManage"
          inactive-text="会员机构管理"
        ></el-switch>
      </el-card>
    </div>
    <div class="zhuti">
      <el-card class="dengji">
        <div style="font-size: 20px; width: 150px" class="text">车型报价管理</div>
        <el-switch
          style="margin-bottom: 10px"
          v-model="authority.offerManage.addVehicleOffer"
          inactive-text="车型报价"
        ></el-switch>
        <el-switch
          class="dengjiSecond"
          style="margin-bottom: 10px"
          v-model="authority.offerManage.vehicleOfferQuery"
          inactive-text="车型列表"
        ></el-switch>

        <!-- 下拉框开始 -->
        <el-form :rules="rules" :model="formInline" class="demo-form-inline">
          <el-form-item class="functionDiv" prop="brands" label="品牌权限">
            <el-select multiple collapse-tags v-model="formInline.brands" placeholder="默认全选">
              <div class="sctionDiv">
                <div id="sctionDivTit" class="sctionDivTit">
                  <p
                    v-for="item in optionsOne"
                    @click="oooo(item.initials)"
                    :key="item.initials"
                  >{{ item.initials }}</p>
                </div>
                <div class="sctionTow">
                  <el-option-group
                    v-for="(group, index) in optionsOne"
                    :key="index"
                    :label="group.initials"
                    :id="group.initials"
                  >
                    <el-option
                      style="text-align: center; font-size: 8px; color: #666666"
                      v-for="item in group.brands"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-option-group>
                </div>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item class="functionDiv"  label="地区选择">
            <el-cascader @change="changeCity" :options="pcas" clearable></el-cascader>
          </el-form-item>
        </el-form>
        <!-- 下拉框结束 -->
      </el-card>
    </div>
    <el-button class="tiSure" type="primary" @click="tiSure">开通</el-button>
  </div>
</template>
<script>
import Qs from "qs";
let pcas = require("../../plugins/code.json");
export default {
  data() {
    //验证手机号的规则;
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      // const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      const regMobile = /^[1][3,4,5,7,8,6,9][0-9]{9}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };
    return {
      rules: {
        brands: [
          { required: true, message: "不能为空", trigger: "change" },
        ],

      },
      optionsOne: [],
      formInline: {
        mobilePhone: "", //用户的手机号
        area: "", //地区
        admCode: "", //地区对应的码
        brands: [], //选择的车型
      },
      pcas: pcas,
      options: [
        {
          value: "超级管理员",
          label: "超级管理员",
        },
        {
          value: "经理",
          label: "经理",
        },
        {
          value: "总监",
          label: "总监",
        },
        {
          value: "主管",
          label: "主管",
        },
        {
          value: "员工",
          label: "员工",
        },
      ],

      options1: [
        {
          value: "销售部",
          label: "销售部",
        },
        {
          value: "运营部",
          label: "运营部",
        },
        {
          value: "产品部",
          label: "产品部",
        },
      ],
      options2: [
        {
          value: "超级管理员",
          label: "超级管理员",
        },
        {
          value: "经理",
          label: "经理",
        },
        {
          value: "总监",
          label: "总监",
        },
        {
          value: "主管",
          label: "主管",
        },
        {
          value: "员工",
          label: "员工",
        },
      ],

      addUserRules: {
        phone: [
          { required: true, message: "手机号码不能为空" },
          {
            validator: checkMobile,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            transform: (value) => value,
            trigger: "blur",
          },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              // var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/;
              var passwordreg = /^\d{6}$/;
              if (!passwordreg.test(value)) {
                callback(new Error("请输入6位数字密码"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      authority: {
        approveModel: {
          orgApprove: false, //合作机构审核
          subjectApprove: false, //主体信息审核
          registerApprove: false, //登记信息审核
          industryApprove: false, //舆情审核
          offerApprove: false, //车型报价审核
        },
        accountModel: {
          staffAuthority: false, //合作机构账号添加
          orgManage: false,
        },
        offerManage: {
          addVehicleOffer: false, //车型报价
          vehicleOfferQuery: false, //车型列表
        },
        userInfor: {
          phone: "",
          userName: "", //用户名
          dept: "", //部门
          password: "",
          roleName: "", //y员工等级
          position: "", //职位
        },
      },

      // userlist: this.$route.query.userlist,
    };
  },
  methods: {
    //  审核接口
    async aa() {
      const { data: res } = await this.$http.get(`/staffAccount/staffList`);
      if (res.code == !200) console.log(res.data);
    },

    // input失去焦点时间
    async onChange() {
      console.log("失去焦点了");
      if (this.authority.userInfor.phone == "") {
        // this.$message.error("手机号不能为空");
        this.tiJiaoOne = false;
        return;
      }
      const regMobile = /^[1][3,4,5,6,7,8][0-9]{9}$/;
      if (!regMobile.test(this.authority.userInfor.phone - 0)) {
        // this.$message.error("请填写正确的手机号");
        this.tiJiaoOne = false;
        return;
      }
      const { data: res } = await this.$http.get(
        `/cooperationMember/isPhone/${this.authority.userInfor.phone}`
      );
      console.log(res);
      if (res.code == 707) {
        this.$message.error("手机号已经绑定主体");
        this.tiJiaoOne = false;
        return;
      } else {
        this.$message.success("手机号可以使用");
        this.tiJiaoOne = true;
      }
    },
    // 进入页面请求数据
    async RequestData() {
      // return;
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleBrand/getBrands`,
        data: {},
      });
      console.log(res.content);
      if (res.resultStates == 1) return this.$message.error("请求失败");
      this.optionsOne = res.content;

      // console.log("111")
    },
    // 添加子账号提交确认
    async tiSure() {
      if (this.tiJiaoOne == false) return this.$message.error("请重试");
      const { data: res } = await this.$http({
        method: "post",
        url: "staffAccount/addStaff",
        data: this.authority,
      });
      if (res.code !== 200) return this.$message.error("添加子账号失败");

      this.formInline.mobilePhone = this.authority.userInfor.phone;

      let a = this.formInline.admCode + "";
      if (a.length != 6) {
        a = a + "00";
      }
      console.log(a - 0);
      this.formInline.admCode = a - 0;
      // if ( == 4) {
      //   this.formInline.admCode = this.formInline.admCode + "00";
      // }
      const { data: ress } = await this.$http({
        method: "post",
        url: "/api/vehicleOffer/addConfig",
        data: this.formInline,
      });
      console.log(ress);
      this.$message.success("添加子账号成功");
      this.$router.go(-1);
    },
    oooo(item) {
      console.log(item);
      let element = document.getElementById(item);
      // element.scrollIntoView({ block: "center", inline: "nearest" });
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    changeCity(e) {
      // this.formInline.a = shi[0].value

      let sheng = this.pcas.filter((item) => {
        return item.value == e[0];
      });
      let shi = sheng[0].children.filter((item) => {
        return item.value === e[1];
      });
      this.formInline.admCode = shi[0].value - 0;
      this.formInline.area = shi[0].label;
    },
  },
  created() {
    // console.log(this.userlist);
    this.RequestData();

    console.log(pcas);
  },
  mounted() {
    this.aa();
  },
};
</script>
<style lang="less" scoped>
.box-card {
  width: 100%;

  .header {
    width: 100px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    opacity: 1;
  }

  .el-col-12 {
    width: 44%;
    margin-left: 50px;
  }
}
.quanxian {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dengji {
  width: 100%;
  .text {
    width: 100px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    color: #666666;
    opacity: 1;
    margin-bottom: 10px;
  }
  /deep/.el-switch__label--left {
    margin-right: 48px;
  }
  /deep/.dengjiSecond {
    margin-left: 50px;
  }
}
.zhuti {
  margin-top: 5px;
}
.tiSure {
  float: right;
  margin-top: 20px;
}
/deep/.el-form--inline .el-form-item__label {
  width: 100px;
}
/deep/.functionDiv {
  margin-bottom: 10px;
}
.sctionDiv {
  display: flex;
}
.sctionDivTit {
  width: 30px;
  position: absolute;
  z-index: 9999;
  background: #f4f7fe;
  height: 100%;
}
.sctionDivTit p {
  text-align: center;
  font-size: 7px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  height: 9px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sctionTow {
  flex: 1;
  position: relative;
}
/deep/.el-select-group__title {
  padding: 0 !important;
  background: #f4f7fe;
  font-size: 8px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1883e3;
  opacity: 1;
}
/deep/.el-switch {
  margin-left: 50px;
}
</style>
<style>
.el-cascader-menu__wrap {
  height: 500px !important;
}
</style>
